import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components'
import { ContentContainer } from '../Components/Common/ContentContainer';
import { HeroBanner } from '../Components/Common/HeroBanner';

const StyledTermsPage = styled.div`
    aside {
        h2 {
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 0px;
            padding-top: 20px;
            font-size: 16px;
            font-weight: 300;

            &:first-child {
                 padding-top: 0px;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    article {
        h3 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        p {
            font-weight: 300
        }
    }
`;

const TermsPage: React.FC = () => {
    const pageContent  = useStaticQuery(graphql`
    query {
        wordpress {
            page(idType: URI, id: "terms-conditions") {
                content
            }
        }
    }
  `);

    const termsContent = pageContent?.wordpress?.page?.content;


    const [getHeaders, setHeaders] = React.useState<any[]>([]);

    React.useEffect(() => {
        const headerElements = Array.from(document.querySelectorAll('h3'));

        const toRender:any =  [];
        for( let i = 0; i < headerElements.length; i++ ) {
            if (!headerElements[i].id && headerElements[i].classList.length <= 0) {
                toRender.push(headerElements[i].innerHTML.toLowerCase().replace(/\s/g, '-'))
            }
        }

        setHeaders(toRender);
    }, [])

    const scrollToSection = (sectionToScrollTo) => {
        const el = document.querySelector(`article h3:nth-of-type(${sectionToScrollTo + 1})`)
        el?.scrollIntoView();
    }

    return (
        <>
            <HeroBanner pageSlug="terms-conditions" minHeight={ 225 } />
            <StyledTermsPage>
                <ContentContainer>
                    <div className="inner-wrapper with-sidebar">
                        <aside className="content-tabs">
                        <h2>Contents</h2>
                            { getHeaders && (
                                getHeaders.map( (header, index) => {
                                    const h = header.replace(/-/g, ' ').split(' ').map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')
                                    return (
                                        <h3
                                            key={ index }
                                            onClick={ () => scrollToSection(index) }
                                        >
                                            { h }
                                        </h3>
                                    )
                                })
                            )}
                        </aside>
                        <article dangerouslySetInnerHTML={{__html:termsContent}}></article>
                    </div>
                </ContentContainer>
            </StyledTermsPage>
        </>
    )
}


export default TermsPage;